import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { LoggerService } from '../../../../shared/sdk';
import moment from 'moment';

@Component({
  selector: 'app-key-performance-indicators',
  templateUrl: './key-performance-indicators.component.html',
  styleUrls: ['./key-performance-indicators.component.scss'],
})
export class KeyPerformanceIndicatorsComponent extends ABaseComponent implements OnInit, AfterViewInit {
  // isSU$: Observable<boolean>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;

  periods: any = [
    { name: 'Last 7 Days', value: [7, 'days'] },
    { name: 'Last Month', value: [1, 'month'] },
    { name: 'Last 3 Months', value: [3, 'months'] },
    { name: 'Last 6 Months', value: [6, 'months'] },
  ];
  period: any = this.periods[3].value;

  groups: any = [
    { name: 'Daily', value: 0 },
    { name: 'Weekly', value: 1 },
    { name: 'Monthly', value: 2 },
  ];
  group: any = this.groups[0].value;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '7276d037-36fa-5e37-ab13-31076ee1b3b9',
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.rebuild();
  }

  rebuild() {
    this.dso = this.buildDataSource();
  }

  grid_onInitialized(e) {}

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      ...{ name: 'refresh', locateInMenu: 'auto', location: 'after', widget: 'dxButton', showText: 'inMenu' },
      options: { icon: 'refresh', text: 'Refresh', hint: 'Refresh', onClick: () => e.component.refresh() },
    });
  }

  ngAfterViewInit(): void {}

  private buildDataSource() {
    const self = this;
    const col = 'EmployeeOnDuty';
    const dayly = {
      $concat: [{ $substr: ['$day', 0, 4] }, '-', { $substr: ['$day', 4, 2] }, '-', { $substr: ['$day', 6, 2] }],
    };
    const $week = {
      $dateFromString: {
        dateString: {
          $concat: [{ $substr: ['$day', 0, 4] }, '-', { $substr: ['$day', 4, 2] }, '-', { $substr: ['$day', 6, 2] }],
        },
      },
    };
    const groupWeekly = {
      $concat: [
        { $substr: ['$day', 0, 4] },
        '-W',
        {
          $cond: {
            if: { $lt: [{ $week }, 10] },
            then: { $concat: ['0', { $toString: { $week } }] },
            else: { $toString: { $week } },
          },
        },
      ],
    };
    const monthly = { $concat: [{ $substr: ['$day', 0, 4] }, '-', { $substr: ['$day', 4, 2] }] };

    const _perSize = (count, $size) => ({
      $cond: { if: { $eq: [{ $size }, 0] }, then: 0, else: { $toInt: { $divide: [count, { $size }] } } },
    });

    const _perSize2 = (count, $size) => ({
      $cond: { if: { $eq: [{ $size }, 0] }, then: 0, else: { $round: [{ $divide: [count, { $size }] }, 2] } },
    });

    const aggregate = [
      { $match: { day: { $gte: +moment().subtract(this.period[0], this.period[1]).format('YYYYMMDD') } } },
      {
        $group: {
          _id: [dayly, groupWeekly, monthly][this.group],
          uniqueVehicles: {
            $addToSet: {
              $cond: { if: { $ne: ['$vehicle.internalId', null] }, then: '$vehicle.internalId', else: '$$REMOVE' },
            },
          },
          uniqueEmployees: {
            $addToSet: {
              $cond: { if: { $ne: ['$employeeId', null] }, then: '$employeeId', else: '$$REMOVE' },
            },
          },
          clients: {
            $addToSet: '$consumers',
          },
          actualTripsCount: { $sum: '$signaturesCount' },
          tripsOnManifestCount: { $sum: '$tripsOnManifestCount' },
          canceledTripsCount: { $sum: '$canceledTripsCount' },
          loadDuration: {
            $sum: { $toInt: { $divide: [{ $subtract: ['$onDutyDuration', '$freeTimeDuration'] }, 3600] } },
          },
          freeTimeDuration: { $sum: { $toInt: { $divide: ['$freeTimeDuration', 3600] } } },
          onDutyEngineOnDuration: { $sum: { $toInt: { $divide: ['$onDutyEngineOnDuration', 3600] } } },
          onDutyEngineOffDuration: { $sum: { $toInt: { $divide: ['$onDutyEngineOffDuration', 3600] } } },
          onDutyIdleDuration: { $sum: { $toInt: { $divide: ['$onDutyIdleDuration', 3600] } } },
          distance: { $sum: { $toInt: '$distance' } },
        },
      },
      {
        $project: {
          _id: 1,
          vehiclesCount: { $size: '$uniqueVehicles' },
          employeesCount: { $size: '$uniqueEmployees' },
          clientsCount: {
            $size: {
              $reduce: {
                input: '$clients',
                initialValue: [],
                in: { $setUnion: ['$$value', '$$this.id'] },
              },
            },
          },
          subscribedClientsCount: {
            $size: {
              $reduce: {
                input: '$clients',
                initialValue: [],
                in: { $setUnion: ['$$value', { $filter: { input: '$$this', as: 'this', cond: '$$this.subscribed' } }] },
              },
            },
          },
          actualTripsCount: 1,
          actualTripsCountPerVehicle: _perSize2('$actualTripsCount', '$uniqueVehicles'),
          tripsOnManifestCount: 1,
          tripsOnManifestCountPerVehicle: _perSize2('$tripsOnManifestCount', '$uniqueVehicles'),
          canceledTripsCount: 1,
          loadDuration: 1,
          loadDurationPerVehicle: _perSize('$loadDuration', '$uniqueVehicles'),
          freeTimeDuration: 1,
          freeTimeDurationPerVehicle: _perSize('$freeTimeDuration', '$uniqueVehicles'),
          onDutyEngineOnDuration: 1,
          onDutyEngineOnDurationPerVehicle: _perSize('$onDutyEngineOnDuration', '$uniqueVehicles'),
          onDutyEngineOffDuration: 1,
          onDutyEngineOffDurationPerVehicle: _perSize('$onDutyEngineOffDuration', '$uniqueVehicles'),
          onDutyIdleDuration: 1,
          onDutyIdleDurationPerVehicle: _perSize('$onDutyIdleDuration', '$uniqueVehicles'),
          distance: 1,
          distancePerVehicle: _perSize('$distance', '$uniqueVehicles'),
        },
      },
    ];
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
    });

    return { store } as DataSourceOptions;
  }

  calculateYesFilterExpression = function (val, op, target) {
    return [this.dataField, ((op === '<>' || op === 'notcontains') && '<>') || '=', 'yes'.includes(val.toLowerCase())];
  };
}

